import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Icon } from '@frx/frx-components';
import { Label } from '../label/Label';
import { formatPhoneNumber, isEqual } from '../../utils';

import styles from './InputField.module.scss';

/**
 * @description this componet requires a refactor for proptype validations
 * TODO: added labelProps prop which includes all the label component props validation, individual prop validation not required
 */

const InputField = React.forwardRef(
  (
    {
      errorText,
      hasTooltip,
      hintText,
      iconProps,
      isTextArea = false,
      label,
      mask,
      onChange,
      toolTipContent,
      labelProps,
      ...props
    },
    ref
  ) => {
    const [isError, setIsError] = React.useState(false);
    const [isTouched, setIsTouched] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const handleChange = (e) => {
      const input = e.currentTarget;

      /** had to direct assign the value to persist the uncontrolled nature */
      if (mask) {
        if (isEqual(mask, 'phone')) {
          input.value = formatPhoneNumber(input.value);
        }
      }

      if (!isError && onChange) {
        onChange(input);
      }

      if (errorText && !input.validity.valid) {
        setErrorMessage(errorText(input.validity));
      }

      setIsError(!input.validity.valid);
    };

    const handleBlur = () => setIsTouched(true);

    const containerClassName = cn(styles.container, props.className);

    let inputStyles;
    let iconComponent;

    if (iconProps) {
      const iconClass = cn({
        [styles.icon]: true,
        [styles.right]: iconProps.placement === 'right',
      });

      inputStyles = cn({
        [styles.inputLeftPadding]: iconProps.placement === 'left',
        [styles.inputRightPadding]: iconProps.placement === 'right',
      });

      iconComponent = (
        <Icon
          name={iconProps.name}
          className={iconClass}
          size={iconProps.size}
          color={iconProps.color}
          onClick={iconProps.handleClick}
        />
      );
    }

    return (
      <div className={containerClassName}>
        <Label
          {...{
            error: isTouched && isError,
            hasTooltip,
            hintText,
            htmlFor: props.name,
            text: label,
            required: props.required,
            toolTipContent,
            ...labelProps,
          }}
        >
          <>
            <div className={styles.inputContainer}>
              {!isTextArea && (
                <input
                  {...props}
                  className={cn(
                    isTouched && isError && styles.error,
                    inputStyles
                  )}
                  id={props.name}
                  disabled={props.disabled}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  ref={ref}
                  /** added for input height. should depends on the icon size than being static */
                  style={
                    iconProps &&
                    iconProps.size && { minHeight: `${iconProps.size}px` }
                  }
                />
              )}
              {isTextArea && (
                <textarea
                  {...props}
                  rows={3}
                  className={cn(
                    isTouched && isError && styles.error,
                    inputStyles
                  )}
                  disabled={props.disabled}
                  id={props.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  ref={ref}
                />
              )}
              {iconComponent}
            </div>
          </>
        </Label>
        {isTouched && isError && (
          <small data-testid='errorMessage' htmlFor={props.name}>
            {errorMessage}
          </small>
        )}
      </div>
    );
  }
);

InputField.propTypes = {
  className: PropTypes.string,
  errorText: PropTypes.func,
  hasTooltip: PropTypes.bool,
  hintText: PropTypes.string,
  isTextArea: PropTypes.bool,
  label: PropTypes.string,
  mask: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  iconProps: PropTypes.shape({
    color: PropTypes.string,
    handleClick: PropTypes.func,
    name: PropTypes.string,
    placement: PropTypes.oneOf(['left', 'right']),
    size: PropTypes.number,
  }),
  labelProps: PropTypes.shape({
    ...PropTypes.instanceOf(Label.propTypes).isRequired,
  }),
  required: PropTypes.bool,
  toolTipContent: PropTypes.string,
  disabled: PropTypes.bool,
};

InputField.displayName = 'InputField';

export default InputField;
