import { useState, useEffect, useRef } from 'react';
import { REG_TYPES } from '../../app.constants';

import { useConfiguration } from '../configuration/ConfigurationContext';
import { useQueryString } from '../QueryStrings';
import { isEqual } from '../utils';
import { useFetcher } from './QueryContext';

const TEAM_RAISER_API = 'CRTeamraiserAPI';
const getParticipationTypesQueryName = `${TEAM_RAISER_API}/getParticipationTypes`;
const getUpSellsQueryName = `${TEAM_RAISER_API}/getUpsell`;

/** This can't be movedto constants because of the order */
const distances = [
  '5K',
  '10K',
  'Half Marathon',
  'Marathon',
  '2-Race Challenge',
  /* '4-Race Challenge', */
];
const commitments = ['Premier', 'Bronze', 'Silver', 'Gold', 'Platinum'];
const virtualCommitments = ['Bronze', 'Silver', 'Gold'];

export const extractPartName = (arr, value) =>
  arr.find((d) => value.trim().toLowerCase().includes(d.trim().toLowerCase()));

const extractParticipationTypeValuesFromName = (name) => {
  const parts = name.split(' ');
  return {
    attendance: isEqual(parts[0], 'Virtual') ? parts[0] : 'InPerson',
    distance: extractPartName(distances, name),
    /* This entire framework is dependent on known names (from LO) and hardcoded arrays. 
    Because commitment levels will differ from virtual to inperson for heroes but we also need to make sure 
    commitment value gets 'Gen' for both in person and virtual in order for reg flow to proceed to final details step, 
    This mess is required below for commitment value in object.
    This is not needed if heroes in person and virtual have identical commitment levels */
    commitment:
      isEqual(parts[0], 'Virtual') &&
      parts.includes('Bronze' || 'Silver' || 'Gold')
        ? extractPartName(virtualCommitments, name)
        : extractPartName(commitments, name) ?? REG_TYPES.GENERAL,
    /* Use this line if commitment levels are the same between in person and virtual for heros
    You will also need to update utils.js */
    /* commitment: extractPartName(commitments, name) ?? REG_TYPES.GENERAL, */
  };
};

const createLocalParticipationTypeStructure = (result) => {
  if (
    result &&
    result.getParticipationTypesResponse &&
    result.getParticipationTypesResponse.participationType === undefined
  )
    return null;

  const participationType =
    result &&
    result.getParticipationTypesResponse &&
    result.getParticipationTypesResponse.participationType;
  const { name } = participationType;
  const participationTypeSelectionValues =
    name && extractParticipationTypeValuesFromName(name);

  return {
    ...participationType,
    ...participationTypeSelectionValues,
  };
};

const createFilterByAttendance = (attendance) => (pc) =>
  new RegExp(`^${attendance}-.*`).test(pc);
// TODO: This might be required going forward in general case so, needs update
// const removeInPersonText = pc => pc.replace('InPerson-', '');

export const useParticipationTypes = ({ attendance }) => {
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [data, setData] = useState(null);
  const {
    settings: {
      gen_promocodes,
      heroes_promocodes,
      appSearchCriteria: { fr_id },
    },
  } = useConfiguration();
  const {
    queryString: { reg_type },
  } = useQueryString();
  const fetcher = useFetcher();

  useEffect(() => {
    setIsLoading(true);
    const promoCodes = reg_type ? heroes_promocodes : gen_promocodes;
    const promoCodesToFetch = attendance
      ? promoCodes.filter(createFilterByAttendance(attendance))
      : // .map(removeInPersonText);
        promoCodes;

    const listOfFetchParams = promoCodesToFetch.map((promotion_code) => ({
      fr_id,
      promotion_code,
    }));

    const promise = fetcher.getAll(
      getParticipationTypesQueryName,
      listOfFetchParams
    );

    promise
      .then((results) => {
        if (!promise.isCancelled())
          setData(
            results
              .map(createLocalParticipationTypeStructure)
              .filter((x) => x !== null)
          );
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        if (!promise.isCancelled()) setIsLoading(false);
      });

    return () => {
      promise.cancel();
    };
  }, [attendance, fetcher, fr_id, gen_promocodes, heroes_promocodes, reg_type]);

  return { data, error, loading };
};

export const useUpsells = (upSellIds = []) => {
  const upSellParams = Array.isArray(upSellIds) ? upSellIds : [upSellIds];
  const { current: queryParams } = useRef({ upSellParams });
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [data, setData] = useState(null);
  const {
    settings: {
      appSearchCriteria: { fr_id },
    },
  } = useConfiguration();
  const fetcher = useFetcher();

  useEffect(() => {
    setIsLoading(true);

    const listOfFetchParams = queryParams.upSellParams.map((uid) => ({
      fr_id,
      upsell_id: uid,
    }));

    const promise = fetcher.getAll(getUpSellsQueryName, listOfFetchParams);

    promise
      .then((results) => {
        if (!promise.isCancelled()) {
          const upsell = results.reduce((acc, u) => {
            acc.push(u.getUpsellResponse.upsell);
            return acc;
          }, []);
          setData(upsell);
        }
      })
      .catch((err) => setError(err))
      .finally(() => {
        if (!promise.isCancelled()) setIsLoading(false);
      });

    return () => promise.cancel();
  }, [fetcher, fr_id, queryParams]);

  return { data, error, loading };
};
