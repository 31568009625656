export const ROUTE_KEYS = Object.freeze({
  ATTENDANCE: 'attendance',
  CONFIRMATION: 'confirmation',
  CONTACT_CONFIRMATION: 'contact-confirmation',
  CONTACT: 'contact',
  DISTANCE: 'distance',
  LEVEL: 'level',
  LOGIN: 'login',
  NOREG: 'noregistrations',
  OPTIONS: 'options',
  PARTICIPATION: 'participation',
  PAYMENT: 'payment',
  PERSONAL: 'personal',
  SEARCH: 'search',
  SUMMARY: 'summary',
  TEAM_OPTIONS: 'teamoptions',
  TEAM: 'team',
  WAIVER: 'waiver',
});

export const RACE_REQUIREMENTS = [
  {
    distance: '5K',
    age: 0,
  },
  {
    distance: '10K',
    age: 12,
  },
  {
    distance: 'Half-Marathon',
    age: 14,
  },
  {
    distance: 'Half Marathon',
    age: 14,
  },
  {
    distance: 'Marathon',
    age: 16,
  },
  {
    distance: '2-Race Challenge',
    age: 0,
  },
  /*  {
    distance: '4-Race Challenge',
    age: 16,
  }, */
];

export const QUILL_MODULES = {
  toolbar: [
    [{ color: [] }],
    ['bold', 'italic', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

export const PAYMENT_TYPES = {
  CREDIT: 'credit',
  PAYPAL: 'paypal',
};

export const ERROR_MESSAGES = {
  CREDIT_CARD_INVALID: 'Credit card number is invalid',
};

export const LUMINATE_API_SECTIONS = {
  CRCONSAPI: 'CRConsAPI',
  CRCONTENTAPI: 'CRContentAPI',
  CRDONATIONAPI: 'CRDonationAPI',
  CRSURVEYAPI: 'CRSurveyAPI',
  CRTEAMRAISERAPI: 'CRTeamraiserAPI',
};

export const PAGINATION_BUTTON_LABELS = {
  NEXT: 'Next',
  PREVIOUS: 'Previous',
};

export const SORT_DIRECTIONS = {
  ASC: 'asc',
  DESC: 'desc',
};

export const LANGUAGE_CODES = {
  EN: 'en_US',
  ES: 'es_US',
};

export const REG_TYPES = {
  HEROES: 'heroes',
  GENERAL: 'Gen',
};

export const MARATHON_HOME_PAGE_URL = 'https://stjude.org/marathon';
export const PRIVACY_POLICY = {
  EN: 'https://www.stjude.org/legal/u-s-privacy-policy-statement.html',
  ES: 'https://www.stjude.org/es/legal/declaracion-de-confidencialidad.html',
};

export const PRIVACY_OPT_OUT_RIGHTS = {
  EN: 'https://www.stjude.org/legal/colorado-privacy-act.html',
  ES: 'https://www.stjude.org/es/legal/ley-de-privacidad-de-colorado.html',
};

export const PRIVACY_OPT_OUT_RIGHTS_QA = {
  EN: 'https://www.qa.stjude.org/legal/colorado-privacy-act.html',
  ES: 'https://www.qa.stjude.org/es/legal/ley-de-privacidad-de-colorado.html',
};

export const DEFAULT_DONATION_LEVEL_ID_QA = 8713;
export const DEFAULT_DONATION_LEVEL_ID_PROD = 10786;
export const DEFAULT_DONATION_VALUE = '$100';
export const DEFAULT_PWID_QA = 4362;
export const DEFAULT_PWID_PROD = 4623;

export const LOADING_LABEL = 'Loading';

export const THANK_YOU_SURVEY_BASE_URL = `https://feedback.stjude.org/jfe/form/SV_ebQp2GotpULzAHz?`;

export const FORCE_HEROES_REGPATH = `heroes`; // This will enforce redirection to heroes reg path temporally. All references will be reverted by june 3 2024

export const FR_ID_SJ_VOLUNTEER_QA = 31053;
export const FR_ID_SJ_VOLUNTEER_PROD = 158764;
