import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Button, Screen } from '../../components';
import { useAuth, useQueryString } from '../../utils';

import styles from './Confirmation.module.scss';

export const Confirmation = () => {
  const { user } = useAuth();
  const {
    queryString: { fr_id, s_locale },
  } = useQueryString();
  const { t } = useTranslation(['confirmation_screen']);

  const handleGetStarted = () =>
    (location.href = 'https://services.stjude.org/events/HeroesCommitUI/');

  React.useEffect(() => i18n.changeLanguage(s_locale), [s_locale]);

  const surveyLink = window.localStorage.getItem('THANK_YOU_SURVEY_URL');

  return (
    <Screen className={styles.container}>
      {user && (
        <div className='confirmation-details'>
          {user.registered ? (
            <>
              <h1>
                {t('confirmation_screen:welcome_back_text')}
                <strong>{user.firstName}!</strong>
              </h1>
              <p>{t('confirmation_screen:already_registered_text')}</p>
            </>
          ) : (
            <>
              <h1>
                {t('confirmation_screen:thank_you_text')}
                <strong>{user.firstName}!</strong>
              </h1>
              <p>{t('confirmation_screen:registered_text')}</p>
            </>
          )}
          <Button isfullWidth variant='primary' onClick={handleGetStarted}>
            {t('confirmation_screen:get_started_text')}
          </Button>
          <p className='confirmation-text-email'>
            {t('confirmation_screen:confirmation_details_sent_text')}{' '}
            <strong>{user.email}</strong>.
          </p>
          <br />
          <div className={styles.yellowAlertSpam}>
            <strong className={styles.yellowAlertSpamTitle}>
              {t('confirmation_screen:check_spam_folder')}
            </strong>
            <p>{t('confirmation_screen:mark_email_as_its_no_junk')}</p>
          </div>
          <p>
            {t('confirmation_screen:how_was_registration_text')}
            <br />
            <a href={surveyLink} target='_blank' rel='noreferrer'>
              {t('confirmation_screen:give_feedback_text')}
            </a>
          </p>
        </div>
      )}
    </Screen>
  );
};
