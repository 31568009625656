import React from 'react';
import { func, object, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  Accordions,
  Button,
  RegistrationProgress,
  Screen,
  StepHeader,
} from '../../components';
import { useRegistrationDataProvider, useQueryString } from '../../utils';
import styles from './Commitment.module.scss';

export const Commitment = ({ onSelection, showSidebar }) => {
  const { t } = useTranslation();
  const { queryString } = useQueryString();
  const { distance, attendance } = queryString;
  const {
    commands: { setActiveTab },
    data: { activeTab, participationData },
  } = useRegistrationDataProvider();

  const handleButtonClick = (commitment) => () => onSelection({ commitment });

  function handleCommitmentText(string) {
    const arr = string.split('\n');

    const descriptionTextArray = arr.filter((item) => !item.includes('//'));

    return descriptionTextArray.map((desc) => (
      <p className='mb-2 mt-2' key={desc.id}>
        {' '}
        {desc}
      </p>
    ));
  }

  function handleBenefitText(string) {
    const arr = string.split('\n');

    const benefitsArray = arr.filter((item) => item.includes('//'));

    const cleanBenefitsArray = benefitsArray.map((item) =>
      item.replace('//', '')
    );

    return cleanBenefitsArray.map((desc) => (
      <li className='mb-2 mt-2' key={desc.id}>
        {' '}
        {desc}
      </li>
    ));
  }

  let buttons;
  const ageRequirementNotification = (
    <div className={styles.alertNeedAdult}>
      <p>
        Participants registering under the age of 12 must be accompanied by
        registered adult
      </p>
    </div>
  );

  if (participationData)
    buttons = (
      <Accordions {...{ activeTab, onTabChange: setActiveTab }}>
        {participationData.map((commitmentLevel) => {
          if (commitmentLevel === undefined) return null;

          const { commitment, description, minimumGoal, soldOut } =
            commitmentLevel;

          const commitmentLabel = `${t(
            `screens.commitment.commitment_types.${commitment
              .toLowerCase()
              .replace(' ', '_')}`
          ).replace('-', ' ')} - ${
            soldOut
              ? t('screens.sold_out_text')
              : minimumGoal.substring(0, minimumGoal.length - 3)
          }`;

          return (
            <AccordionItem key={commitment} disabled={soldOut}>
              <Accordion
                {...{
                  id: commitment,
                  label: commitmentLabel,
                  disabled: soldOut,
                }}
              />
              <AccordionPanel {...{ id: commitment }}>
                <>
                  {description && (
                    <>
                      {handleCommitmentText(description)}
                      <ul>{handleBenefitText(description)}</ul>
                    </>
                  )}
                </>
                <Button
                  isfullWidth
                  variant='primary'
                  data-tracking={`registration-commitment-${commitment}`}
                  onClick={handleButtonClick(
                    commitment.replace(' ', '-'),
                    soldOut
                  )}
                >
                  {`${t('screens.commitment.raise_text')} ${minimumGoal.substring(0, minimumGoal.length - 3)}`}
                </Button>
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordions>
    );

  return (
    <Screen className={styles.container} showSidebar={showSidebar}>
      <StepHeader />
      <RegistrationProgress value={40} />
      <h2>{t('screens.commitment.choose_attendance_text')}:</h2>
      <p>{t('screens.commitment.choose_attendance_sub_text')}.</p>
      {(isEqual(distance, '5K') || isEqual(distance, '2-Race-Challenge')) &&
        isEqual(attendance, 'InPerson') &&
        ageRequirementNotification}
      <div className={styles.buttons}>{buttons}</div>
    </Screen>
  );
};

Commitment.propTypes = {
  data: object,
  onSelection: func,
  showSidebar: bool,
};
