import React from 'react';
import { array, bool, func, number, object, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from '..';
// import { colors } from '../../styles';
import { Form, InputField } from '../forms';
import { isEqual } from '../../utils';

import styles from './DonationArray.module.scss';

export const DonationArray = ({
  donationAmounts,
  hasDonateButton = false,
  kickStartAmount,
  minimumAmount = 5,
  ...props
}) => {
  const [otherDonationAmount, setOtherDonationAmount] = React.useState();

  const { t } = useTranslation(['options']);

  // const {
  //   secondary: { swatch4 },
  // } = colors;

  // const otherStyleCenter = {
  //   textAlign: 'center',
  // };

  // const iconData = {
  //   name: 'close',
  //   placement: 'right',
  //   handleClick: props.handleCloseArray,
  //   color: swatch4,
  // };
  const disabled = false;

  const isOtherActive =
    kickStartAmount &&
    donationAmounts.filter(
      (d) =>
        String(d).trim().replace('$', '') ===
        String(kickStartAmount).trim().replace('$', '')
    ).length === 0;

  const clearKickStartAmount =
    kickStartAmount &&
    !isNaN(Number(String(kickStartAmount).replace('$', ''))) &&
    Number(kickStartAmount.replace(/[$,]/g, ''));

  const handleOtherAmountChange = (e) => setOtherDonationAmount(e.value);

  const handleOnFormChange = (fields, hasErrors) => {
    console.log('onformchange', fields);
    props.handleSelectAmount(fields);
    const { setIsOtherAmountInvalid } = props;
    if (setIsOtherAmountInvalid && hasErrors) setIsOtherAmountInvalid(true);
    if (setIsOtherAmountInvalid && !hasErrors) setIsOtherAmountInvalid(false);
  };

  const donateButtonText =
    (kickStartAmount &&
      !isNaN(Number(kickStartAmount.replace('$', ''))) &&
      `${t('donate')} ${kickStartAmount}`) ||
    t('donate');

  React.useEffect(() => {
    if (clearKickStartAmount) setOtherDonationAmount(clearKickStartAmount);
    if (!isOtherActive) setOtherDonationAmount();
  }, [isOtherActive, clearKickStartAmount]);

  return (
    <>
      <div className={styles.kickstart}>
        {!hasDonateButton && <h2>{t('kickstart_amount')}</h2>}
        {!hasDonateButton && <p>{t('kickstart_subtitle')}</p>}
      </div>
      {isEqual(String(kickStartAmount).trim(), `${t('other')}`) ||
      isOtherActive ? (
        // <Form onChange={handleOnFormChange}>
        //   <div className={styles.otherDonationContainer}>
        //     <InputField
        //       className={styles.otherInput}
        //       defaultValue={otherDonationAmount}
        //       errorText={(amount) => {
        //         if (amount.rangeUnderflow)
        //           return `${t('online_don_min')} $${minimumAmount}`;
        //         if (amount.rangeOverflow) return t('amount_less');
        //         if (
        //           amount.badInput ||
        //           amount.patternMismatch ||
        //           amount.stepMismatch
        //         )
        //           return t('amount_format');
        //       }}
        //       iconProps={iconData}
        //       inputMode='decimal'
        //       max={999999.99}
        //       min={minimumAmount}
        //       name='kickStartAmountField'
        //       onChange={handleOtherAmountChange}
        //       pattern='^[0-9]\d{0,9}(\.\d{1,3})?%?$'
        //       placeholder='$0.00'
        //       ref={props.otherKickStartAmountRef}
        //       step='0.01'
        //       type='number'
        //     />
        //     {/* <Button xs selected className={styles.otherButton}>
        //       {t('other')}
        //     </Button> */}
        //   </div>
        // </Form>
        <div className={props.donationsContainerStyle}>
          {donationAmounts.map((d) => {
            if (d === 'Other') {
              return (
                <Form onChange={handleOnFormChange} key={d}>
                  <div className={styles.otherDonationContainer}>
                    <InputField
                      className={styles.otherInput}
                      defaultValue={otherDonationAmount}
                      errorText={(amount) => {
                        if (amount.rangeUnderflow)
                          return `${t('online_don_min')} $${minimumAmount}`;
                        if (amount.rangeOverflow) return t('amount_less');
                        if (
                          amount.badInput ||
                          amount.patternMismatch ||
                          amount.stepMismatch
                        )
                          return t('amount_format');
                      }}
                      // iconProps={iconData}
                      inputMode='decimal'
                      max={999999.99}
                      min={minimumAmount}
                      name='kickStartAmountField'
                      onChange={handleOtherAmountChange}
                      pattern='^[0-9]\d{0,9}(\.\d{1,3})?%?$'
                      placeholder='$0.00'
                      ref={props.otherKickStartAmountRef}
                      step='0.01'
                      type='number'
                    />
                    {/* <Button xs selected className={styles.otherButton}>
                      {t('other')}
                    </Button> */}
                  </div>
                </Form>
              );
            }
            return (
              <Button
                data-tracking={`donate-${d}`}
                key={d}
                onClick={props.handleSelectAmount}
                selected={isEqual(
                  String(kickStartAmount).trim().replace('$', ''),
                  String(d).trim().replace('$', '')
                )}
                xs
                disabled={disabled}
              >
                {d}
              </Button>
            );
          })}
        </div>
      ) : (
        <div className={props.donationsContainerStyle}>
          {donationAmounts.map((d) => (
            <Button
              data-tracking={`donate-${d}`}
              key={d}
              onClick={props.handleSelectAmount}
              selected={isEqual(
                String(kickStartAmount).trim().replace('$', ''),
                String(d).trim().replace('$', '')
              )}
              xs
              disabled={disabled}
            >
              {d}
            </Button>
          ))}
        </div>
      )}
      {hasDonateButton && (
        <div className={styles.donateButtonContainer}>
          <Button
            variant='donate'
            className={styles.donateButton}
            onClick={props.handleDonateClick}
            disabled={disabled}
          >
            {donateButtonText}
          </Button>
        </div>
      )}
    </>
  );
};

DonationArray.propTypes = {
  donationAmounts: array,
  donationsContainerStyle: string,
  handleCloseArray: func,
  handleDonateClick: func,
  handleSelectAmount: func,
  hasDonateButton: bool,
  kickStartAmount: string,
  minimumAmount: number,
  otherKickStartAmountRef: object,
  setIsOtherAmountInvalid: func,
};
