import React from 'react';
import { string, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CheckboxField, Overlay } from '../../../../components';
import styles from './WaiverField.module.scss';
import { useQueryString } from '../../../../utils';

export const WaiverField = ({ waiver, overlayCommands, ...rest }) => {
  const { t } = useTranslation();
  const { queryString } = useQueryString();
  const { reg_type } = queryString;

  const showWaiverAccordingToPartType = () => {
    if (reg_type && reg_type.toLowerCase().includes('heroes')) {
      return 'screens.personal_info.cancellation_policy_heroes';
    }
    return 'screens.personal_info.cancellation_policy_general';
  };

  return (
    <div className={styles.container}>
      <CheckboxField
        className={styles.waiverCheckbox}
        {...rest}
        name='termsAndConditions'
        label={
          <>
            <span>{t('screens.personal_info.i_agree_to')}</span>
            <Overlay
              placeholder={t('screens.personal_info.terms_and_cond')}
              title={t('screens.personal_info.terms_and_cond')}
              text={waiver}
              overlayCommands={overlayCommands}
            />
          </>
        }
        required
      />
      <CheckboxField
        {...rest}
        name='cancellationPolicy'
        className={styles.waiverCheckbox}
        label={
          <>
            <span>{t('screens.personal_info.have_read')}</span>
            <Overlay
              placeholder={t('screens.cancellation_policy_text')}
              title={t('screens.cancellation_policy_text')}
              text={t(showWaiverAccordingToPartType())}
              overlayCommands={overlayCommands}
            />
          </>
        }
        required
      />
    </div>
  );
};

WaiverField.propTypes = {
  waiver: string,
  overlayCommands: object,
};
