import React from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import { Commitment } from './Commitment';
import { Distance } from './Distance';
import { Attendance } from './Attendance';
import {
  capitalize,
  useQueryString,
  useRegistrationDataProvider,
} from '../../utils';
import { ROUTE_KEYS } from '../../app.constants';

export const Participation = ({ showSidebar }) => {
  const { queryString, buildCleanPath, redirect } = useQueryString();
  const { attendance, commitment, distance, reg_type } = queryString;
  const {
    data: { joinTeamId },
  } = useRegistrationDataProvider();

  const handleSelection = (selection) =>
    redirect(ROUTE_KEYS.PARTICIPATION, selection);

  if (commitment && distance) {
    const code = ['InPerson', distance, commitment].map(capitalize).join('_');
    if (joinTeamId)
      return (
        <Redirect
          to={buildCleanPath(ROUTE_KEYS.OPTIONS, {
            code,
            team: 'join-team',
            reg_type,
          })}
        />
      );
    if (reg_type)
      return (
        <Redirect to={buildCleanPath(ROUTE_KEYS.TEAM, { code, reg_type })} />
      );
    return <Redirect to={buildCleanPath(ROUTE_KEYS.TEAM, { code })} />;
  }

  if (distance && !commitment)
    return (
      <Commitment onSelection={handleSelection} showSidebar={showSidebar} />
    );

  if (!distance)
    return <Distance onSelection={handleSelection} showSidebar={showSidebar} />;

  return <Attendance onSelection={handleSelection} />;
};

Participation.propTypes = {
  showSidebar: PropTypes.bool,
};
