import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { QueryLoading } from '@frx/frx-components';
import { isEqual } from 'lodash';
import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  Accordions,
  Button,
  RegistrationProgress,
  Screen,
  StepButton,
  StepHeader,
} from '../../components';
import { LOADING_LABEL, REG_TYPES } from '../../app.constants';
import {
  stringReplace,
  useQueryString,
  useRegistrationDataProvider,
} from '../../utils';

import styles from './Distance.module.scss';

export const Distance = ({ onSelection, showSidebar }) => {
  const { t } = useTranslation();

  const {
    queryString: { reg_type },
  } = useQueryString();
  const {
    commands: { setActiveTab },
    data: { activeTab, distanceData, participationLimits },
  } = useRegistrationDataProvider();

  useEffect(() => {
    setActiveTab();
  }, [setActiveTab]);

  const handleButtonClick = (distance) => () => {
    if (reg_type) {
      return onSelection({ distance });
    }
    onSelection({ distance, commitment: REG_TYPES.GENERAL });
  };

  function handleCommitmentText(string) {
    const arr = string.split('\n');

    const descriptionTextArray = arr.filter((item) => !item.includes('//'));

    return descriptionTextArray.map((desc) => (
      <p className='mb-2 mt-2' key={desc.id}>
        {' '}
        {desc}
      </p>
    ));
  }

  function handleBenefitText(string) {
    const arr = string.split('\n');

    const benefitsArray = arr.filter((item) => item.includes('//'));

    const cleanBenefitsArray = benefitsArray.map((item) =>
      item.replace('//', '')
    );

    return cleanBenefitsArray.map((desc) => (
      <li className='mb-2 mt-2' key={desc.id}>
        {' '}
        {desc}
      </li>
    ));
  }

  return (
    <Screen className={styles.container} showSidebar={showSidebar}>
      <StepHeader />
      <RegistrationProgress value={20} />
      <h2>{t('common:screens.distance.choose_distance_text')}:</h2>
      {isEqual(reg_type, 'heroes') ? (
        <div className={styles.buttons}>
          {distanceData.map(({ distance, soldOut }) => {
            const distanceText = t(
              `screens.distance.distance_types.${distance
                .toLowerCase()
                .replace(' ', '_')}`
            );
            return (
              <StepButton
                key={distance}
                title={distanceText}
                soldOut={soldOut}
                subtitle={soldOut ? t('screens.sold_out_text') : ''}
                data-tracking={`registration-distance-${distance.replace(
                  ' ',
                  '-'
                )}`}
                onClick={handleButtonClick(stringReplace(distance, ' ', '-'))}
              />
            );
          })}
        </div>
      ) : (
        <div className={styles.accordion}>
          {participationLimits.isLoading && (
            <QueryLoading label={LOADING_LABEL} />
          )}
          <Accordions activeTab={activeTab} onTabChange={setActiveTab}>
            {distanceData.map(({ description, distance, fee, soldOut }) => {
              const soldOutLabel = soldOut
                ? ` - ${t('screens.sold_out_text')}`
                : ` - ${fee}`;
              const distanceLabel =
                t(
                  `screens.distance.distance_types.${distance
                    .toLowerCase()
                    .replace(' ', '_')}`
                ) + soldOutLabel;
              return (
                <AccordionItem key={distance} disabled={soldOut}>
                  <Accordion
                    id={distance}
                    label={distanceLabel}
                    disabled={soldOut}
                  />
                  <AccordionPanel id={distance}>
                    <>
                      {description && (
                        <>
                          {handleCommitmentText(description)}
                          <ul>{handleBenefitText(description)}</ul>
                        </>
                      )}
                    </>
                    <Button
                      isfullWidth
                      data-tracking={`registration-distance-${distance.replace(
                        ' ',
                        '-'
                      )}`}
                      onClick={handleButtonClick(
                        stringReplace(distance, ' ', '-')
                      )}
                      variant='primary'
                    >
                      {`${t(
                        `screens.distance.distance_types.${distance
                          .toLowerCase()
                          .replace(' ', '_')}`
                      )}`}
                    </Button>
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordions>
        </div>
      )}
    </Screen>
  );
};

Distance.propTypes = {
  onSelection: PropTypes.func,
  showSidebar: PropTypes.bool,
};
